@font-face {
    font-family: 'Source Sans 3';
    src: local('Source Sans 3'), url('./assets/fonts/SourceSans3.ttf') format('truetype');
  
  }
  
  body {
    margin: 0;
    font-family: 'Source Sans 3' !important;
    font-size: 1rem !important;
    
  }