@font-face {
  font-family: 'Source Sans 3';
  src: local('Source Sans 3'), url('./assets/fonts/SourceSans3.ttf') format('truetype');

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Source Sans 3', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
