.hidden-navbar{
    display: none;
   
 }
 .children{
     height: 95vh;
     overflow: auto;
     /* width: 100%; */
 }
 .col-lg-2{
     background-color: black;
     border-bottom: 1px solid lightgray;
     max-width: 250px;
 }

 .active-highlight-sidebar {
    background-color:#e9ecef !important;
    color: black !important;
     border-bottom: none !important; 
    border-top: none !important;
    border-radius: 10px;
  
  }

  .custom-input-group:hover {
    background-color: #e9ecef;
   border-radius: 10px;
   border: 0.5px solid #00796b3b !important;
   cursor: pointer;
   padding: 0px;
   margin: 0px;
  }

  .input-group-text{
    background-color: none;
    border:'none'
  }