.border-left {
    border-left: 1px solid #454545;
  }
  .border-right {
    border-right: 1px solid #454545;
  }
  .border-bottom-light {
    border-bottom: 1px solid  black !important;
  }
  .border-bottom {
    border-bottom: 1px solid  #454545 !important;
  }
  .border-top-bottom {
    border-top: 1px solid  black !important;
    border-bottom: 1px solid  black !important;
  }
  .border-top {
    border-top: 1px solid #454545;
  }
  .border {
    border: 1px solid #454545;
  }
  .border-black {
    border-right: 1px solid #454545;
  }
  .table-border-head{
    border-top: 1px solid #454545 !important;
    border-right: 1px solid #454545 !important;
    border-bottom: 1px solid #454545 !important;
  }
  .table-border-last{
    border-top: 1px solid #454545 !important;
    border-bottom: 1px solid #454545 !important;
  }
  .border-double {
      border-right: 1px solid black;
      text-align: right;
    }
  .border-tax{
    border-right: 1px solid #454545;
    border-bottom: 1px solid #454545 !important;
  }
  .border-for-received{
    border-right: 1px solid #454545 !important;
    border-bottom: 1px solid #454545 !important;
  }
  .custom-thead {
      background-color: white !important;
  }
  .total-row{
    background-color: #F8F9FA;
  }
  .border-col{
    border-right:1px solid  black;
    border-left:1px solid  black;
  }
  .author-col{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-page{
    display: flex;
    font-size: 18px;
    font-family: helvetica, arial, sans-serif;
  }
  .page-color{
    background-color: lightsalmon;
  }